p, h1 {
  margin: 0;
}

.App {
  width: 100vw;
  height: 100vh;
  flex: 1;
  background-color: #282c34;
}

.App-header {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 5px 10px;
  background: rgba(40, 44, 52, 1);
  background: -moz-linear-gradient(top, rgba(40, 44, 52, 1) 0%, rgba(40, 44, 52, 1) 83%, rgba(56, 56, 56, 1) 99%, rgba(56, 56, 56, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(40, 44, 52, 1)), color-stop(83%, rgba(40, 44, 52, 1)), color-stop(99%, rgba(56, 56, 56, 1)), color-stop(100%, rgba(56, 56, 56, 1)));
  background: -webkit-linear-gradient(top, rgba(40, 44, 52, 1) 0%, rgba(40, 44, 52, 1) 83%, rgba(56, 56, 56, 1) 99%, rgba(56, 56, 56, 1) 100%);
  background: -o-linear-gradient(top, rgba(40, 44, 52, 1) 0%, rgba(40, 44, 52, 1) 83%, rgba(56, 56, 56, 1) 99%, rgba(56, 56, 56, 1) 100%);
  background: -ms-linear-gradient(top, rgba(40, 44, 52, 1) 0%, rgba(40, 44, 52, 1) 83%, rgba(56, 56, 56, 1) 99%, rgba(56, 56, 56, 1) 100%);
  background: linear-gradient(to bottom, rgba(40, 44, 52, 1) 0%, rgba(40, 44, 52, 1) 83%, rgba(56, 56, 56, 1) 99%, rgba(56, 56, 56, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#282c34', endColorstr='#383838', GradientType=0);

  h1 {
    font-size: 1.2em;
  }


}

#App-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    margin-bottom: 15px;
  }

  .label {
    padding: 5px 10px;
    color: white;
  }

  .App-QrReader, .App-Username-Input {
    flex-direction: row;
    width: 100%;

    input {
      width: calc(100% - 8px - 8px - 4px);
      max-height: 38px;
      padding: 8px;
      font-size: 1em;
      color: #333333;
    }

  }

  .App-Location-Selector, .App-Theme-Selector, .App-DefaultSetup-Selector, .App-ActivateButton {
    width: 100%;
  }

  .App-ActivateButton {
    position: fixed;
    bottom: 0;
    margin-bottom: 0;
  }

  .App-DefaultSetup-Selector {
    > div {
      > div {
        margin-bottom: 5px !important;

        > div {
          align-items: center;
        }
      }
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex1 {
  display: flex;
  flex-grow: 1;
}

.flex2 {
  display: flex;
  flex-grow: 2;
}

.flex3 {
  display: flex;
  flex-grow: 3;
}

.justify-center {
  justify-content: center;
}
